<template>
  <div class="vg_wrapper">
    <EditHeader
      :isShow="isShow"
      :btn="btn"
      :special="true"
      :strForm="{ status: formStatus }"
      @openEdit="openEdit"
      @closeEdit="closeEdit"
      @submit="submit"
      @refresh="initData"
    >
      <div slot="functionButton" class="vg_ml_8">
        <el-button :disabled="!isShow" icon="el-icon-printer" class="vg_mb_8" type="info" size="small" @click="exportPDF"> 打印 </el-button>
        <el-button :disabled="!isShow" icon="el-icon-printer" class="vg_mb_8" type="info" size="small" v-if="formStatus === 2" @click="exportPDF">
          下载
        </el-button>
      </div>
      <div slot="otherButton">
        <div class="flexHV vg_mr_8">
          <span>托收合同号：</span>
          <el-input disabled size="small" :value="contract_no" style="width: 120px"></el-input>
        </div>
      </div>
      <div slot="specialStatus">
        <div class="flexHV">
          <span class="vg_tag_label">状态：</span>
          <el-tag :type="helper.getStatusName(formStatus).type">{{ helper.getStatusName(formStatus).name }}</el-tag>
        </div>
      </div>
    </EditHeader>
    <DynamicForm
      v-bind="$attrs"
      :total-width="mainForm.totalWidth"
      :split="mainForm.split"
      :form-list="mainForm.formProperties"
      ref="dynamicForm"
      class="dynamicForm"
      :is-show="isShow"
      :computedData="{ payment_date: payment_date, podr_fetotal: podr_fetotal, podr_total: podr_total }"
    />
    <DynamicUTable
      ref="dynamicUTable"
      :tableData="subTableData"
      :columns="contractSubTableProperties"
      :need-pagination="false"
      :is-show="isShow"
      @selection-change="val => (subSelections = val)"
    >
    </DynamicUTable>
    <el-row>
      <el-col>
        <el-tabs v-model="activeName">
          <el-tab-pane label="费用明细" name="first" :key="'first'">
            <div class="vg_mb_16">
              <el-button
                :disabled="isShow"
                type="success"
                plain
                size="small"
                @click="
                  contractFeesTableData.push({
                    contract_fess_name: '',
                    contract_fess_amount: '',
                    contract_fess_remark: ''
                  })
                "
                >新增</el-button
              >
              <el-button :disabled="isShow" type="danger" plain size="small" @click="feesDel">删除</el-button>
            </div>
            <DynamicUTable
              ref="dynamicUTable2"
              :tableData="contractFeesTableData"
              :columns="feesSubTableProperties"
              :need-pagination="false"
              :is-show="isShow"
              @selection-change="val => (feesSelections = val)"
            >
            </DynamicUTable>
            <!--<ContractFeesList :contractForm="otherForm" @feetPriceChange="feetPriceChange" ref="contractFeeListRef" />-->
          </el-tab-pane>
        </el-tabs>
      </el-col>
    </el-row>
    <el-row class="vg_mt_16 vd_bortop">
      <el-col>
        <inputUser :isCookie="false" :stffForm="stffForm" ref="userData"></inputUser>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import EditHeader from '@/views/component/editHeaderLine.vue';
import inputUser from '@/views/component/inputUser';
import DynamicForm from '@/components/DynamicFormV2.vue';
import { cloneDeep } from 'lodash';
import {
  contractMainFormProperties,
  contractSubTableProperties,
  feesSubTableProperties,
  otherForm
} from '@/views/CollectionManagement/ContractManage/contract';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { deleteTableRow, objectArrayReduce } from '@assets/js/arrayUtils';
import { contractAPI } from '@api/modules/contract';
import { changeStaffForm, downloadFile } from '@api/public';
import { getDateNoTime } from '@assets/js/dateUtils';
import { requiredReminderAndUGrid } from '@assets/js/domUtils';
import { BigNumber } from 'bignumber.js';
import UrlEncode from '@assets/js/UrlEncode';

export default {
  name: 'contractEditMain',
  components: {
    DynamicUTable,
    DynamicForm,
    EditHeader,
    inputUser
  },
  data() {
    return {
      activeName: 'first',
      isShow: true,
      btn: {},
      stffForm: {},
      mainForm: {
        split: 8,
        totalWidth: 24,
        formProperties: contractMainFormProperties
      },
      // otherForm: {},
      subTableData: [],
      subSelections: [],
      subTableMap: new Map(),
      contractSubTableProperties: cloneDeep(contractSubTableProperties),
      contractFeesTableData: [],
      feesSelections: [],
      feesTableMap: new Map(),
      feesSubTableProperties: cloneDeep(feesSubTableProperties),
      formStatus: 0,
      contract_no: '',
      contract_id: '',
      custList: [],
      deptList: [],
      stffList: []
    };
  },
  mounted() {
    this.initData();
  },
  computed: {
    payment_date() {
      let cTimeIndex = this.mainForm.formProperties.findIndex(({ prop }) => prop === 'contract_time');
      let suppPaymentIndex = this.mainForm.formProperties.findIndex(({ prop }) => prop === 'supp_payment');
      let paymentDateIndex = this.mainForm.formProperties.findIndex(({ prop }) => prop === 'payment_date');
      let contractTime = this.mainForm.formProperties[cTimeIndex].model;
      let suppPayment = this.mainForm.formProperties[suppPaymentIndex].model;
      if (!contractTime || !suppPayment) {
        this.mainForm.formProperties[paymentDateIndex].model = '';
        return '自动计算';
      }
      let paymentDate = new Date(contractTime).getTime() + suppPayment * 60 * 60 * 24 * 1000;
      this.mainForm.formProperties[paymentDateIndex].model = paymentDate;
      return getDateNoTime(paymentDate, false);
    },
    podr_fetotal() {
      let podrFetotalIndex = this.mainForm.formProperties.findIndex(({ prop }) => prop === 'podr_fetotal');
      let temp = new BigNumber(objectArrayReduce(this.contractFeesTableData, 'contract_fess_amount'));
      if (!temp.isFinite()) {
        this.mainForm.formProperties[podrFetotalIndex].model = '';
        return '自动计算';
      }
      this.mainForm.formProperties[podrFetotalIndex].model = temp.toFixed(4);
      return temp.toFixed(4);
    },
    podr_total() {
      let podrPrtotalIndex = this.mainForm.formProperties.findIndex(({ prop }) => prop === 'podr_prtotal');
      let podrTotalIndex = this.mainForm.formProperties.findIndex(({ prop }) => prop === 'podr_total');
      let temp = new BigNumber(this.podr_fetotal).plus(this.mainForm.formProperties[podrPrtotalIndex].model);
      if (!temp.isFinite()) {
        this.mainForm.formProperties[podrTotalIndex].model = '';
        return '自动计算';
      }
      this.mainForm.formProperties[podrTotalIndex].model = temp.toFixed(4);
      return temp.toFixed(4);
    }
  },
  methods: {
    initData() {
      this.getContractData();
      this.changeMainFormProperties();
    },
    openEdit() {
      this.isShow = false;
      this.$emit('isShow', this.isShow);
    },
    closeEdit() {
      this.$confirm('是否撤销编辑?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.isShow = true;
          this.$emit('isShow', this.isShow);
          this.initData();
          this.$message({
            type: 'info',
            message: '已撤销编辑!'
          });
        })
        .catch(() => {});
    },
    async changeMainFormProperties() {},
    getContractData() {
      const props = JSON.parse(UrlEncode.decode(this.$route.query.key));
      contractAPI.getContractById({ contract_id: props.form_id }).then(({ data }) => {
        this.subTableData = data.form.contractModrList;
        this.contractFeesTableData = data.form.contractFeesList;
        this.btn = data.btn;
        this.contract_no = data.form.contract_no;
        this.contract_id = data.form.contract_id;
        this.formStatus = data.form.status;
        this.mainForm.formProperties.forEach(item => (item.model = data.form[item.prop]));
        changeStaffForm(this.stffForm, data.form);
      });
    },
    submit(formName) {
      this.$confirm('确认保存?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          let dynamicUTable1 = await this.$refs.dynamicUTable.fullValidateMsg();
          let dynamicUTable2 = await this.$refs.dynamicUTable2.fullValidateMsg();
          this.$refs.dynamicForm.$refs.form.validate((valid, object) => {
            if (valid && !dynamicUTable1 && !dynamicUTable2) {
              this.saveInfo();
            } else {
              let tempStr = '';
              if (dynamicUTable1) tempStr += `子表-${dynamicUTable1}`;
              if (dynamicUTable2) tempStr += `费用子表-${dynamicUTable2}`;
              requiredReminderAndUGrid(object, tempStr);
            }
          });
        })
        .catch(err => console.error(err));
    },
    saveInfo() {
      contractAPI.editContract(this.getSaveData()).then(({ data }) => {
        this.$message.success('保存成功!');
        this.initData();
        this.isShow = true;
        this.$emit('isShow', this.isShow);
      });
    },
    getSaveData() {
      let formModel = this.$refs.dynamicForm.getFormModel();
      formModel.contract_id = this.contract_id;
      formModel.contract_no = this.contract_no;
      formModel.contractModrList = this.subTableData.concat(Array.from(this.subTableMap.values()));
      formModel.contractFeesList = this.contractFeesTableData.concat(Array.from(this.feesTableMap.values()));
      return Object.assign(formModel, otherForm);
    },
    //取消
    cancel() {
      this.$confirm('确定取消新增?', '提示', {
        confirmButtonText: '是',
        cancelButtonText: '否',
        type: 'warning'
      })
        .then(() => {
          this.resetForm('contractForm');
          this.$router.go(-1);
          this.$message({
            type: 'info',
            message: '已取消新增!'
          });
        })
        .catch(() => {});
    },
    delRow() {
      let { tableData, map } = deleteTableRow('contract_id', this.subTableData, this.subTableMap, this.subSelections);
      this.subTableData = tableData;
      this.subTableMap = map;
    },
    feesDel() {
      let { tableData, map } = deleteTableRow('contract_fess_id', this.contractFeesTableData, this.feesTableMap, this.feesSelections);
      this.contractFeesTableData = tableData;
      this.feesSelections = map;
    },
    exportPDF() {
      contractAPI.exportExcel({ contract_id: this.contract_id }).then(({ data }) => {
        downloadFile({ fileUrl: data.url, fileName: '托收合同', type: '.pdf', open: true });
      });
    }
  }
};
</script>

<style scoped lang="scss"></style>
